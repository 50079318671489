[dir="rtl"] .card-img-wrapper {
  margin-left: 10px;
}

[dir="rtl"] .home-section-title::before {
  margin-left: 10px;
}

[dir="rtl"] .card-mobile-button {
  text-align-last: left;
}
[dir="ltr"] .card-mobile-button {
  text-align-last: right;
}

[dir="ltr"] .select-box-label {
  margin-right: 20px;
}
[dir="rtl"] .select-box-label {
  margin-left: 20px;
}

[dir="ltr"] .date-field-wrapper h4 {
  margin-right: 10px;
}
[dir="rtl"] .date-field-wrapper h4 {
  margin-left: 10px;
}

[dir="ltr"] .card-img-wrapper {
  margin-right: 10px;
}

[dir="ltr"] .select-country-block ion-button {
  display: flex;
}
[dir="ltr"] .select-country-block ion-button img {
  order: -1;
  margin: 0 auto 0 0;
}

[dir="ltr"] .home-section-title::before {
  margin-right: 10px;
}

[dir="ltr"] .custom-checkbox {
  margin-right: 5px;
}

[dir="rtl"] .custom-checkbox {
  margin-left: 5px;
}

[dir="ltr"] .accordion-label {
  margin-left: 10px;
}
[dir="rtl"] .accordion-label {
  margin-right: 10px !important;
}

[dir="ltr"] .header-top-block.online-catalog-block {
  margin-left: auto;
}
[dir="rtl"] .header-top-block.online-catalog-block {
  margin-right: auto;
}

[dir="ltr"] .arrow-down {
  margin-left: 10px;
}
[dir="rtl"] .arrow-down {
  margin-right: 10px;
}

[dir="rtl"] .card-img-wrapper {
  margin-left: 10px;
}
[dir="ltr"] .send-us-text ion-icon {
  margin-right: 5px;
}

[dir="lrt"] .navigation-list .login-button {
  margin-left: 20px;
}

[dir="rtl"] .navigation-list .login-button {
  margin-right: 20px;
}

[dir="lrt"] .unlogged-banner .join-button {
  margin: 0 auto 0 0;
}
[dir="rtl"] .unlogged-banner .join-button {
  margin: 0 0 0 auto;
}

[dir="ltr"] .installer-card-img {
  margin-left: 15px;
}
[dir="rtl"] .installer-card-img {
  margin-right: 15px;
}

[dir="ltr"] .navigation-list .login-button {
  margin-left: 20px;
}
[dir="rtl"] .navigation-list .login-button {
  margin-right: 20px;
}


[dir="rtl"] .navigation-list .login-button img {
  transform: rotate(180deg);
}

@media screen and (max-width: 991px) {
  [dir="rtl"] .unlogged-banner .join-button {
    margin: 0 auto;
  }
  [dir="rtl"] .map-details h3 {
    text-align: right !important;
  }
}
