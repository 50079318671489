html {
    --smallTextSize: 12px;
    --textSize: 14px;
    --labelSize: 16px;
    --mainTextColor: #000000;
    --currentColor: #2B4B85;
    --currentColorNotActive: rgba(43, 75, 133, .4);
    --shadowColor: #EAEDF3;
    --notActiveTextColor: #AAB7CE;
    --secondary-bg: #E5EBF4;
    --highlightedColor: #BF5151;
    --containerPadding: 20px;
    --text-btn-color: #ffffff;
    --content-color: #012b5d;
    --light-color: #2cb4dd;

    --section-bg-color: #f2f1f6;
    --active-desk-color: #012b5d;
}
