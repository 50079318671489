@media screen and (min-width: 400px) {
  ion-tab-bar {
    height: 90px;
  }
  ion-tab-button.footer-btn {
    bottom: 8px;
    max-width: 54px;
    max-height: 54px;
  }
  ion-tab-button.footer-btn ion-icon {
    font-size: 28px;
  }
  .knowledge-card-title {
    font-size: 16px;
  }
  .knowledge-card ion-card-content {
    padding: 10px;
  }
  .footer.mobile-footer {
    height: 96px;
  }
  .footer-link ion-icon {
    font-size: 35px;
  }
  .footer.mobile-footer {
    padding: 0 35px;
  }
  .card-modal .card-date {
    font-size: 12px;
  }
}

@media screen and (min-width: 576px) {
  .home_buy {
    min-height: 331px;
  }
  .main-page-content {
    padding-bottom: 0;
  }
  .main-block {
    flex-grow: 0;
  }
  .picker-wrapper {
    top: 30px !important;
    border-radius: 15px !important;
  }
  .title-toolbar {
    padding: 20px 0;
    margin: 0;
  }
  .knowledge-card .card-media {
    width: 100%;
  }
  .knowledge-card ion-card-content {
    width: 100%;
  }
  .page-title {
    font-size: 1.5rem;
  }
  .home-slider {
    display: none;
  }

  ion-tab-bar {
    display: none;
  }
  .custom-header {
    display: none;
  }
  .desktop-header {
    display: block;
  }
  .send-us-text ion-icon {
    display: block;
    font-size: 26px;
  }
  .send-us-text {
    justify-content: flex-start;
    font-size: 22px;
  }
  .choose-file-section {
    display: none;
  }
  .support-nav-buttons .secondary-btn {
    display: none;
  }
  .support-field {
    width: 70% !important;
  }

  .support-page .personal-msg-row {
    display: flex;
  }
  .or-call {
    font-weight: bold;
  }
  .main-block {
    margin-bottom: 0 !important;
  }
  .tabs-inner {
    margin-bottom: 0;
  }
  .card-img-wrapper {
    width: 120px;
    height: 120px;
  }
  .card {
    border-bottom: 1px solid #e8e7e7;
    padding: 18px 0;
  }
  .card-mobile-button {
    display: none;
  }
  .points-table {
    font-size: 16px;
  }
  .sign-up-accordion,
  .custom-toolbar ion-segment {
    width: 450px;
    margin: 0 auto;
  }

  .sign-up-accordion ion-label,
  .sign-up-accordion p {
    line-height: 24px;
  }
  .button-select-custom {
    width: 155px;
  }
  .sign-up-accordion .field-wrapper {
    margin-bottom: 5px;
  }
  .login-form {
    width: 450px;
    margin: 0 auto;
  }

  .segment-btn {
    --background-checked: var(--light-color);
    --color-checked: #ffffff;
    --indicator-color: var(--light-color);
    font-weight: bold;
  }

  /* .segment-btn {
    color: var(--mainTextColor)
  } */

  .segment-btn:hover {
    --color: var(--mainTextColor);
  }
  .segment-toolbar {
    margin-bottom: 20px;
  }
  .knowledge-item {
    padding: 10px;
  }
  .container.update-profile-grid {
    width: 450px;
    margin: 0 auto;
  }
  .forgot-pass-content {
    width: 500px;
  }
  .footer.mobile-footer {
    display: none;
  }
  .knowledge-modal {
    padding: 30px;
  }
  /* ion-picker-column {
    overflow: auto;
  } */
  .picker-toolbar {
    justify-content: center !important;
    padding: 0 20px;
  }
  .picker-wrapper ion-picker-column {
    max-width: none !important;
  }
  .picker-toolbar {
    order: 1;
  }
  .picker-opts {
    top: 0 !important;
  }
  .status-update-box {
    height: 183px;
  }
  .knowledge-card {
    display: flex;
    flex-direction: column;
  }
  .knowledge-card .card-media {
    height: 230px;
  }
}
@media screen and (min-width: 768px) {
  .support-field {
    width: 50% !important;
    max-width: 475px;
  }
  .points-table ion-col {
    font-size: 16px;
  }
  .knowledge-modal .modal-wrapper {
    width: 90%;
    max-width: 700px;
    height: 600px;
  }
  .back-header {
    display: none;
  }
}
@media screen and (min-width: 992px) {
  .support-field {
    width: 50% !important;
    max-width: 475px;
  }
  .footer-menu-list {
    justify-content: flex-start;
  }
}
@media screen and (min-width: 1170px) {
  .support-field {
    width: 50% !important;
    max-width: 475px;
  }
  .footer-menu-list {
    justify-content: flex-start;
  }
  .container {
    width: 1170px;
  }
  .custom-btn.small-btn.yes-btn {
    --padding-start: 40px;
    --padding-end: 40px;
    margin-top: 15px;
  }
}

@media screen and (max-width: 991px) {
  .level-form-wrapper {
    display: inline-block;
    text-align: left;
    height: 50vh;
    width: 90%;
    margin-bottom: 20px;
  }
  .unlogged-banner .welcome-msg {
    display: flex;
    padding-bottom: 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .unlogged-banner .welcome-msg hr {
    width: 50%;
    margin: 30px auto;
  }
  .unlogged-banner .join-button {
    margin: 0 auto;
  }
  .header-main-nav-list {
    justify-content: center;
  }
  .how-works.unlogged-section .item {
    padding: 0 80px;
    margin-bottom: 20px;
  }
  .item-wrapper:nth-child(5) {
    display: none;
  }
  .home_buy .item-wrapper {
    text-align: center;
    --ion-grid-columns: 12;
    --ion-grid-column-padding: 20px;
  }
  .footer-menu-col {
    justify-content: center;
  }
  .status-update-box {
    --ion-grid-column-padding: 15px;
    --ion-grid-column-padding-md: 10px;
  }
  .status-update-box .num {
    font-size: 30px;
  }
  .navigation-list ion-col {
    min-width: 150px;
    text-align: center;
    margin: 3px 0;
  }
}

@media screen and (max-width: 767px) {
  .status-update-box .num {
    font-size: 40px;
  }
  .header-main-nav-list ion-item ion-router-link {
    font-size: 14px;
  }
  .knowledge-card .card-media iframe {
    height: 175px;
  }
  .status-update-box:last-child {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    margin-top: 10px;
  }
  .level-form-wrapper {
    display: inline-block;
    text-align: left;
    height: 50vh;
    width: 100%;
    margin-bottom: 20px;
  }
  
  
}
@media screen and (max-width: 575px) {
  .support-field.custom-input {
    display: none;
  }
  .desktop-footer {
    display: none;
  }
  .card-desktop-button {
    display: none;
  }
  .knowledge-card {
    display: flex;
  }
  .knowledge-item {
    margin-bottom: 7px;
  }
  .home-desktop-data {
    display: none;
  }
  .error-img-wrapper {
    width: 200px;
  }
  .forgot-pass-content {
    width: 100%;
  }
  .knowledge-card .card-media iframe {
    height: 150px;
  }

  .personal-msg-row {
    display: none;
  }
  .main-block .personal-msg-row {
    display: flex;
  }
  .branch-popover .popover-content {
    max-width: 100% !important;    
    width: auto !important;
    /* min-width: auto !important; */
    right: 12px !important;
    left: 12px !important;    
  }
  .branch-popover .popover-wrapper {
    padding: 0 10px;
  }
  .navigation-list_desktop {
    display: none;
  }
  .navigation-list_mobile {
    display: block;
  }
  .branch-popover {
    --width: 100%;
    padding: 0 10px;
  }
}
@media screen and (max-width: 380px) {
  .slide-content-block .block-left .num-small {
    font-size: 21px;
  }
  .card-modal .card-name {
    font-size: 18px;
  }
  .card-modal .platinum-card-wrapper .card-date {
    font-size: 9px;
  }
  .how-works.unlogged-section .item {
    padding: 0 15px;
  }
}

@media (prefers-color-scheme: dark) {
  ion-content {
    --background: #ffffff;
  }
  .header-middle {
    background: #ffffff;
  }
  .title-toolbar {
    --background: #ffffff;
  }
  ion-card {
    background: #ffffff;
  }
  ion-segment {
    background-color: #ffffff;
  }
  .mobile-menu-list .item {
    --background: transparent;
    --inner-box-shadow: none;
    --inner-border-width: 0px;
  }
}
