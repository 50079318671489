* {
  --ion-font-family: "Lato", sans-serif;
  --font-size: var(--textSize);
  --ion-default-font: "Lato", sans-serif;
}
a {
  cursor: pointer;
}
p {
  margin-block-start: 0;
  margin-block-end: 0;
}

h2,
h4 {
  margin: 0;
}

.ion-page.app-wrapper {
  overflow: visible;
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);
}

button {
  width: auto;
}
.container {
  width: 100%;
  margin: 0 auto;
  padding: 0;
}
.ion-page {
  overflow-y: auto;
}

.main-page-content {
  --padding-top: var(--containerPadding);
  padding-bottom: 80px;
}

.font-bold {
  font-weight: bold;
}
/* 
.main-container {
  --padding-start: var(--containerPadding);
  --padding-end: var(--containerPadding);
  padding-right: var(--containerPadding);
  padding-left: var(--containerPadding);
  max-width: 100%;
  margin: 0 auto;
  --ion-grid-width-sm: 500px;
  --ion-grid-width-lg: 500px;
} */

.custom-header {
  padding: 11px 0;
}
.custom-header {
  background-color: var(--content-color);
}

.header-toolbar {
  --background: var(--secondary-bg);
  --border-width: 0;
}
.header-link {
  text-decoration: none;
}

.header-ios ion-toolbar:last-of-type {
  --border-width: 0;
}

.custom-header .main-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.position-center {
  text-align: center;
}
.position-left {
  text-align: left;
}
.position-right {
  text-align: right;
}

.custom-toolbar {
  margin-bottom: 30px;
  --background: #ffffff;
}

.custom-toolbar ion-segment {
  background-color: transparent;
  /* width: 220px; */
  margin: 0 auto;
}

.custom-toolbar .md.ion-segment .custom-toolbar .custom-toolbar-link {
  font-size: var(--labelSize);
  min-width: auto;
  min-height: auto;
  line-height: 1;
  text-transform: capitalize;
}
.custom-toolbar-link {
  --color-checked: var(--currentColor);
  --color: var(--mainTextColor);
}

.custom-input input.native-input,
.custom-select,
.custom-textarea textarea.native-textarea {
  background: var(--shadowColor);
  border-radius: 15px;
  padding: 11px 20px !important;
  color: var(--mainTextColor);
  --placeholder-color: var(--notActiveTextColor);
}
.custom-input {
  width: 100%;
}
.field-wrapper .item-inner {
  padding-inline-end: 0;
}
.list-md-lines-full .item,
.list-md .item-lines-full {
  --border-width: 0;
  --min-height: auto;
}

.list-ios-lines-full .item,
.list-ios .item-lines-full {
  --border-width: 0;
  --min-height: auto;
}

:host(.item-interactive.item-has-focus) {
  --highlight-background: none;
}

ion-button.custom-btn {
  --background: var(--currentColor);
  text-transform: capitalize;
  --border-radius: 20px;
  --padding-start: 39px;
  --padding-end: 39px;
  height: 40px;
  --min-height: auto;
  --color: var(--text-btn-color);
}

ion-button.custom-btn .button-native {
  padding: 10px 39px;
}

.secondary-btn {
  --color: var(--notActiveTextColor);
  text-transform: initial;
  --background: transparent;
}

.select-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.select-box-label {
  flex: none;
}

.custom-select button {
  position: static !important;
}

.field-wrapper {
  margin-bottom: 5px;
  overflow: auto;
  --border-width: 0;
  --padding-bottom: 0px;
  --padding-end: 5px;
  --padding-start: 5px;
  --padding-top: 0px;
  --inner-border-width: 0;
  --background-focused: transparent;
  --background-activated: transparent;
}

.field-wrapper.small-margin {
  margin-bottom: 10px;
}

.field-wrapper h4 {
  font-size: 1rem;
  margin-bottom: 10px;
}
.date-field-wrapper h4 {
  margin-bottom: 0;
}

.forgot-pass-content {
  --padding-top: 20px;
  text-align: center;
}

ion-label.select-box-label {
  overflow: visible;
}

.item.sc-ion-label-ios-h,
.item .sc-ion-label-ios-h {
  overflow: visible;
}

.d-block {
  display: block;
}

.select-icon-inner {
  border: none !important;
}

.custom-date {
  background: var(--shadowColor);
  border-radius: 15px;
  padding: 11px 20px !important;
  --placeholder-color: var(--notActiveTextColor);
}

.date-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

ion-icon.custom-icon {
  color: var(--currentColor);
}

/* .page-content {
  --padding-top: 30px;
} */

.page-title {
  font-size: 1.2rem;
  color: var(--mainTextColor);
  text-align: center;
  text-align: center;
  font-weight: bold;
}

.title-toolbar {
  margin-bottom: 18px;
  margin-top: 10px;
  --min-height: 40px;
}

.card {
  margin: 0 0 20px;
  box-shadow: none;
  transition: 0.3s ease-in-out;
}

.card-block {
  display: flex;
  align-items: flex-start;
}

.card-header {
  padding: 0;
}

.card-content-wrapper {
  padding: 10px 0 0;
}

.card-img-wrapper {
  width: 80px;
  height: 80px;
}

.card-img-wrapper img {
  width: 100%;
}

.card-title {
  font-size: var(--textSize);
  color: var(--mainTextColor);
}

.card-description {
  color: var(--highlightedColor);
}

.custom-checkbox {
  --border-radius: 4px;
  --border-color: var(--currentColor);
  --size: 20px;
  --background: #fff;
  position: relative;
  top: 4px;
}

ion-button.secondary-btn {
  height: auto;
  min-height: 25px;
}

.select-icon .select-icon-inner {
  border-top: 10px solid;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}

/* ion-tab-bar {
  padding-bottom: 0;
  --border: none;
  --background: url(../../src/img/footer_shape.svg) no-repeat;
  background-size: 100% auto;
  background-position: bottom;
  padding-right: var(--containerPadding);
  padding-left: var(--containerPadding);
  justify-content: space-between;
  position: relative;
  overflow: visible;
  height: 84px;
  align-items: flex-end;
  --background-color: transparent;
  --ion-color-base: transparent !important;
} */

.mobile-footer .footer-btn {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  --color-selected: var(--text-btn-color);
  background: transparent;
  color: var(--text-btn-color);
  width: 30px;
  height: 30px;
}
.footer-btn {
  bottom: -16px;
}

/* ion-tab-button:first-of-type {
  margin-right: 27px;
} */
.mobile-footer .footer-btn:first-of-type {
  margin-right: 27px;
}

/* ion-tab-button:last-of-type {
  margin-left: 27px;
} */

.mobile-footer .footer-btn:nth-of-type(2) {
  right: 43px;
}

.mobile-footer .footer-btn:nth-of-type(4) {
  /* margin-left: auto; */
  left: 25px;
}

.mobile-footer .footer-btn:nth-of-type(3) {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-53%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: var(--light-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: calc(10px + 10%);
}

.footer-link {
  color: var(--text-btn-color);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}
.footer-link ion-icon {
  font-size: 25px;
}

.tabs-inner {
  margin-bottom: -84px;
}

.btn-wrapper {
  text-align: right;
}

.footer-btn-list {
  position: relative;
  width: 100%;
}

ion-modal {
  --backdrop-opacity: 0.9;
  --background: #000;
  --height: auto;
  position: relative;
  padding: 50px 29px;
}

.modal-wrapper .ion-page {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 29px;
  width: 100%;
  background: var(--secondary-bg);
  text-align: center;
}

ion-modal .modal-wrapper {
  border-radius: 20px;
  width: 315px;
  height: 231px;
}

.modal-text .coast-text {
  margin-top: 8px;
}

.modal-text .coast-text .number-of-points {
  color: var(--highlightedColor);
}

.custom-btn.small-btn {
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-top: 8px;
  --padding-bottom: 8px;
}

.points-table {
  font-size: var(--smallTextSize);
  padding-left: 0;
  padding-right: 0;
}

.points-table ion-col {
  font-size: 14px;
}

.header-table-row {
  margin-bottom: 15px;
}

.header-table-row ion-col {
  font-weight: bold;
}

.points-table .points-col {
  color: var(--currentColor);
  font-weight: bold;
}

.body-table-row {
  margin-bottom: 8px;
}

.points-table-row {
  word-wrap: normal;
  padding-left: 0;
  padding-right: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
}

.home-page .greeting-text {
  font-size: var(--textSize);
  line-height: 20px;
}

.greeting-row {
  margin-bottom: 20px;
}

.greeting-pic-wrapper {
  --size: auto;
}

.greeting-col {
  display: flex;
  align-items: center;
}

.points-row .pic-col {
  background: url(../../src/img/semicircle.svg) no-repeat;
  background-size: contain;
  background-position: bottom;
  height: 137px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.points-content {
  text-align: center;
}

.points-row .pic-row {
  margin-bottom: 10px;
}

.points-content .points-number {
  font-size: 64px;
  font-weight: bold;
  color: var(--highlightedColor);
}

.inactive-text {
  color: #bdbdbd;
  font-size: 12px;
}
.options-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.options-row .option-col {
  text-align: center;
}

.home-page ion-grid {
  --ion-grid-padding: 0;
}

.options-row .option-col .option-text {
  margin-bottom: 4px;
}
.options-row .option-col .option-num {
  color: var(--currentColor);
  font-weight: bold;
}
.home-list {
  width: 100%;
}
.home-list ion-item {
  --min-height: auto;
  --border-color: transparent;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 0;
  --padding-start: 0;
}

.home-list-icon {
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-list-label {
  margin: 0;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}
.home-list-label span {
  font-size: var(--textSize);
}
ion-title {
  padding-inline-start: 0;
  padding-inline-end: 0;
}
ion-toolbar {
  --min-height: 20px;
}

.greeting {
  margin-bottom: 30px;
}
.bold {
  font-weight: bold;
}
.settings-row {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.update-profile-grid ion-label {
  color: var(--mainTextColor);
}
.settings-row ion-text {
  color: var(--mainTextColor);
}
.settings-row a {
  display: block;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--mainTextColor);
  text-decoration: none;
}
.settings-row ion-button {
  height: auto;
  width: auto;
  --padding-start: 0;
  --padding-end: 0;
}

.settings-row ion-button .ion-icon {
  margin-right: 0;
  --color: #bdbdbd;
}
.settings-row .lang-link {
  --background: transparent;
  border: none;
  height: 20px;
  --box-shadow: none;
  --color: var(--currentColor);
  letter-spacing: 0.02em;
  font-weight: normal;
  text-transform: none;
  font-size: 18px;
}
.settings-rows-box {
  margin-bottom: 40px;
}
.settings-row .sign-out-link {
  height: 20px;
  --color: var(--currentColor);
}

.text-center {
  text-align: center;
  display: block;
}
.send-us-text {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--active-desk-color);
}

.send-us-text ion-icon {
  font-size: 22px;
  display: none;
}
.support-field {
  margin-bottom: 15px;
}
.support-field.support-select {
  width: 50%;
}

.or-call {
  margin: 10px 0;
}
.choose-file-btn {
  margin: 10px 0;
}

.forgot-pass-content ion-label {
  text-align: left;
  margin-bottom: 5px;
  display: block;
}
.recapcha-wrapper {
  margin: 10px 0;
}
.recapcha-wrapper .rc-anchor-normal .rc-anchor-checkbox-label {
  width: auto !important;
}
.button-select-custom {
  --border-radius: 15px;
  --background: var(--shadowColor);
  --color: var(--mainTextColor);
  display: flex;
  align-items: center;
  height: 33px;
  width: 155px;
  font-size: 14px;
  display: flex;
  text-transform: none;
  letter-spacing: normal;
  --padding-start: 15px;
  --padding-end: 15px;
}

.button-select-custom.button-date-custom {
  background: var(--shadowColor);
  border-radius: 15px;
  height: 33px;
  color: var(--mainTextColor);
  display: flex;
  align-items: center;
  font-size: 16px;
  width: auto;
  min-width: 120px;
  padding: 11px 20px;
}

.button-select-custom .accordion-custom .accordion__button {
  background-color: var(--secondary-bg);
}

.accordion-custom .accordion__panel {
  padding: 10px 5px;
}

.back-icon {
  --ripple-color: var(--currentColor);
  --color: var(--currentColor);
}
.error-section {
  text-align: center;
}
.error-img-wrapper {
  width: 410px;
  max-width: 100%;
  margin: 0 auto 10px auto;
  height: auto;
}
.error-text {
  margin-bottom: 25px;
}
.error-text p {
  margin-bottom: 5px;
}

ion-picker {
  touch-action: none;
}

.company-title {
  color: var(--text-btn-color);
  font-size: 18px;
}

.burger-button {
  color: var(--text-btn-color);
}

.mobile-menu-user {
  position: relative;
  padding: 32px 5px 22px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--content-color);
  color: var(--text-btn-color);
}

.mobile-menu-user .user-photo {
  width: 46px;
  height: 46px;
  margin: 0 auto;
  border: 3px solid #ffffff;
  background-color: #ffffff;
}
.mobile-menu-user .user-greeting {
  margin: 12px auto 5px auto;
  font-weight: 500;
  font-size: 16px;
}
.mobile-menu-user .user-button {
  color: var(--text-btn-color);
  font-size: 12px;
  height: 30px;
  text-decoration: none;
  border: 1px solid #67809e;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 150px;
  margin: 0 auto;
}

.mobile-menu-user .content {
  text-align: center;
}

.mobile-menu-list a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--content-color);
  font-size: 14px;
  opacity: 0.7;
  width: 100%;
  height: 100%;
}

.mobile-menu-list {
  background: #ffffff;
}

.mobile-menu-list a img {
  margin-right: 18px;
}

.sign-out-link {
  text-decoration: none;
  color: var(--light-color);
  font-size: 12px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: auto;
  --padding-start: 0;
  --padding-end: 0;
  height: 20px;
}
.field-wrapper {
  --highlight-color-focused: none;
  --inner-padding-end: 0;
  --background: #ffffff;
}
.field-wrapper ion-label {
  color: var(--mainTextColor) !important;
}
form.reg-form .select-box-label {
  color: var(--mainTextColor) !important;
}
form.reg-form ion-text {
  color: var(--mainTextColor) !important;
}
.login-form {
  background: #ffffff;
}
.sign-up-accordion .field-wrapper {
  margin-bottom: 8px;
}
.sign-up-accordion .field-wrapper .custom-field {
  flex: none;
}

.sign-up-accordion .accordion__heading .accordion__button {
  padding: 9px 18px;
  outline: none;
  display: flex;
  align-items: center;
}

.accordion__item + .accordion__item {
  border: none;
}

.mobile-unlogged-menu-list ion-item {
  --padding-start: 0;
  padding-top: 7px;
}
.mobile-unlogged-menu-list a {
  padding-left: 20px;
}

.select-country-block {
  max-width: 500px;
  margin: 0 auto;
  width: 100%;
  background: white;
}
.select-country-block h1 {
  color: var(--content-color);
  font-size: 22px;
}
.select-country-page {
  width: 100%;
}
.select-country-page ion-item {
  --padding-end: 10px;
  --padding-start: 10px;
  --background: transparent;
  --border-color: transparent;
}
.select-country-page ion-item:last-child {
  --border-color: transparent;
}
.mobile-country-menu-list {
  background-color: #ffffff;
  padding: 0 5px;
}
.mobile-country-menu-list ion-button {
  width: 100%;
  font-size: 1rem;
  height: 32px;
  --background: transparent;
  --background-activated: transparent;
  --color: var(--content-color);
}
.mobile-country-menu-list ion-button img {
  margin-left: auto;
  
}
.mobile-country-menu-list ion-item:first-child img {
  
  width: auto;
}
[dir="ltr"] .mobile-country-menu-list ion-button img {
  margin-left: auto;
}
[dir="rtl"] .mobile-country-menu-list ion-button img {
  margin-right: auto;
}
.home-slider.swiper-container {
  margin: 0;
  min-height: 205px;
}
.first-slide {
  padding: 20px 17px 30px 17px;
}
.home-slider ion-slide {
  min-height: 205px;
  position: relative;
  background-image: url(../../src/img/banner.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.home-slider ion-slide .slide-content {
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
}

.home-slider .slide-content-block {
  display: flex;
  margin: 8px 0;
}
.home-slider .slide-content {
  margin: auto;
}

.slide-content-block .block-left {
  padding-right: 20px;
  min-width: 10px;
  width: 40%;
  padding-left: 5px;
  padding-top: 10px;
  text-align: center;
  border-right: rgba(255, 255, 255, 0.4) solid 1px;
}
.slide-content-block .block-right {
  text-align: left;
  padding-left: 10px;
}
.slide-content-block .heading {
  font-size: 12px;
}
.slide-content-block {
  color: var(--content-color);
}
.slide-content-block .num {
  text-align: left;
  font-weight: bold;
  line-height: 24px;
  font-size: 13px;
}
.slide-content-block .block-left .num {
  text-align: center;
  font-size: 35px;
  line-height: 40px;
}

.slide-content-block .block-left .num-small {
  text-align: center;
  font-size: 29px;
  line-height: 40px;
}

.home-slider .swiper-pagination-bullet {
  margin: 0 12px !important;
}

.home-slider .swiper-pagination-bullet {
  background: #706e6f;
  opacity: 1;
}
.home-slider .swiper-pagination-bullet-active {
  background: #fff;
}
.personal-msg {
  font-size: 14px;
  display: block;
}
.personal-msg a {
  color: #7a8591;
  text-decoration: underline;
}
.personal-msg p {
  color: var(--content-color);
  margin-top: 15px;
}

.main-links .main-item {
  text-align: center;
}

.main-links .main-item img {
  margin-bottom: 3px;
}
.main-links .main-item p {
  font-size: 14px;
  color: var(--content-color);
}
.main-links .main-item a {
  display: block;
  padding: 15px 10px 10px;
  text-decoration: none;
  background: #f4f4f4;
  height: 100%;
}
.slide-card {
  position: absolute;
  bottom: 0;
  right: 10px;
  z-index: 11;
  --padding-start: 0;
  --padding-end: 0;
  width: 120px;
  height: auto;
  --background: transparent;
}
.slide-card img {
  display: block;
  width: 100%;
  height: 100%;
}
.card-modal {
  padding: 0;
}
.card-modal .modal-wrapper {
  --width: 100%;
  --height: 100%;
}

.modal-img {
  width: 100%;
  height: 100%;
}

.close-modal {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  --background: transparent;
  --box-shadow: none;
  --background-hover: transparent;
  --background-focused: transparent;
  --background-activated: transparent;
  width: 30px;
  height: 30px;
}

.close-modal::before {
  background: #4e525f;
  color: #d7d8db;
  content: "×";
  font-size: 26px;
  height: 30px;
  line-height: 26px;
  font-weight: 400;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 30px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-block {
  margin-bottom: 70px !important;
  flex-grow: 1;
}

.support-nav-buttons {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
}
.support-nav-buttons .secondary-btn {
  position: absolute;
  left: 0;
  top: 43%;
  transform: translateY(-50%);
  font-size: 14px;
  display: flex;
  align-items: center;
}
.choose-file-section {
  display: flex;
  align-content: center;
  margin-bottom: 15px;
}
.upload-button {
  --background: var(--shadowColor);
  --border-radius: 10px;
  height: 25px;
  --padding-end: 12px;
  --padding-start: 12px;
  --color: var(--mainTextColor);
  --background-activated: var(--shadowColor);
  --box-shadow: none;
  text-transform: none;
}

.choosen-file-name {
  font-size: 12px;
  color: var(--notActiveTextColor);
}
.files-names-block {
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.files-names-block span {
  display: block;
}
.desktop-header {
  display: none;
}
.splash-wrapper {
  width: 100%;
  height: 100%;
}

.header-top-button {
  --background: none;
  font-size: 13px;
  position: relative;
  cursor: pointer;
  text-transform: capitalize;
  color: var(--mainTextColor);
  border: none;
  --box-shadow: none;
  --border-width: 0;
  height: 20px;
  text-decoration: none;
}
.header-top ion-col {
  height: 23px;
}
.dc {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-top {
  background-color: var(--section-bg-color);
}

.change-branch-block {
  border-left: 1px solid #d9d8dd;
  border-right: 1px solid #d9d8dd;
}
.jc-sb {
  justify-content: space-between;
}

/* .header-middle {
  padding: 20px 0;
} */
.header-middle .logo-col {
  line-height: 50px;
  vertical-align: middle;
}

.header-middle .logo-col a {
  display: inline-block;
}
.header-middle .logo-col a img {
  vertical-align: middle;
}
.header-main-nav-list {
  display: flex;
  align-items: center;
  background: transparent;
  padding: 0;
}
.header-main-nav-list ion-item {
  --border-width: 0;
  --border-color: transparent;
  --background: transparent;
}

.header-main-nav-list ion-item a:hover {
  text-shadow: 1px 0 #012b5d;
  border-bottom: 4px solid #012b5d;
  outline: 0;
}
.header-main-nav-list ion-item a {
  color: var(--active-desk-color);
  padding: 8px 0;
  text-decoration: none;
  height: 100%;
  display: flex;
  height: 43px;

  align-items: center;
}
.header-bottom {
  background-color: var(--section-bg-color);
  --ion-grid-padding: 0;
}

.support-page .personal-msg-row {
  display: none;
}
.footer.mobile-footer {
  position: absolute;
  bottom: -3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  border-top: none;
  border: none;
  background: url(../../src/img/footer_shape.svg) no-repeat;
  background-size: 100% auto;
  background-position: bottom;
  background-color: transparent;
  overflow: visible;
  height: 84px;
  width: 105%;
  left: 50%;
  transform: translateX(-50%);
}
.footer.mobile-footer:before {
  display: none;
}
.desktop-footer {
  text-align: center;
  background: #0a559c;
  /* background: var(--active-desk-color); */
}
.desktop-footer .copyright {
  color: var(--text-btn-color);
  margin-top: 20px;
}
.desktop-footer * {
  background-color: transparent;
}
.footer-menu-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
.footer-menu-list ion-item {
  --border-color: transparent;
}

.footer-menu-list a {
  color: var(--text-btn-color);
  padding: 5px 17px;
  border-right: #fff solid 1px;
  font-size: 14px;
  cursor: pointer;
}
.footer-menu-list a:last-of-type {
  border-right: none;
}

.footer-menu-list a:hover {
  text-decoration: underline;
}
.footer-logo {
  align-self: flex-end;
}

.header-top .user-photo {
  width: 20px;
  height: 20px;
  margin-right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-top .user-photo ion-icon {
  font-size: 20px;
  color: var(--mainTextColor);
}

.arrow-down {
  background: url(../img/arrow-down-small-grey.png) no-repeat;
  background-size: cover;
  width: 10px;
  height: 5px;
}

.orientation-right .num {
  text-align: right;
}
.orientation-right.support-nav-buttons {
  justify-content: flex-start;
}

.home-section-title {
  font-size: 22px;
  color: #012b5d;
  line-height: 36px;
  font-weight: normal;
}

.home-section-title::before {
  display: inline-block;
  vertical-align: middle;
  /* margin-right: 10px; */
}
.home_status-update .home-section-title::before {
  content: url(../img/home_icons/status-update-icon.png);
}
.home_my-points .home-section-title::before {
  content: url(../img/home_icons/my-points-icon.png);
}
.home_status-update .home-section-title::before {
  content: url(../img/home_icons/what-you-can-buy-icon.png);
}

.status-update-box .box-heading {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.status-update-box .box-heading h4 {
  font-size: 1rem;
}
.status-update-box .box-heading .tooltip-pic {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  font-weight: bold;
  font-size: 16px;
  border-radius: 50%;
  color: #fff;
  background-color: #919191;
  cursor: pointer;
}
.status-update-box .num {
  color: #15acda;
  font-size: 2.25em;
}

.status-update-box {
  --ion-grid-column-padding: 25px;
  --ion-grid-column-padding-md: 15px;
  border-right: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.home_status-update .status-update-box:last-child {
  border-right: 0;
}

.view-all-link {
  font-size: 14px;
  display: block;
  text-decoration: underline;
  color: #012b5d;
}

.home-section-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.home_buy .item {
  position: relative;
}
.home_buy .item .plus-link {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #919191;
  cursor: pointer;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
}

.home_buy .item-wrapper {
  text-align: center;
  --ion-grid-columns: 5;
  --ion-grid-column-padding: 20px;
}
.home_buy .num-points {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #15acda;
  width: 100px;
  height: 23px;
  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home_buy .item {
  border: 1px solid #ccc;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home_buy .item img {
  width: 100px;
  height: 100px;
}

.home_buy .item-wrapper .name {
  font-size: 1rem;
}
/* .card-desktop-button {
  margin-left: auto;
} */
.card .details-wrapper {
  margin: 15px 0 5px 0;
  padding: 0 30px;
  transition: 0.3s;
}

.unlogged-banner {
  background: url(../img/lp-banner.jpg) no-repeat center/cover #172f64;
  height: 425px;
  box-shadow: inset 0 6px 6px rgba(0, 0, 0, 0.4);
}

.unlogged-banner-grid {
  height: 100%;
  --ion-grid-padding: 0;
}

.unlogged-banner-grid ion-row {
  height: 100%;
}

.unlogged-banner .welcome-msg {
  display: flex;
  padding-bottom: 30px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}
.unlogged-banner .welcome-msg h1 {
  color: #ffffff;
  font-size: 36px;
  margin-bottom: 30px;
  line-height: 46px;
}

.unlogged-banner .join-button {
  width: 200px;
  text-align: center;
  transition: 0.3s;
  margin: 0 auto 0 0;
  color: #08338f;
  text-decoration: none !important;
  background-color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: bold;
  display: block;
  text-transform: none;
}
.unlogged-banner .join-button:hover {
  background-color: #14a1cc;
}
.unlogged-banner .welcome-msg hr {
  opacity: 0.3;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 0;
  width: 70%;
  display: block;
  background-color: #ffffff;
}

.store-block img {
  margin-right: 10px;
}

.banner-phone-block {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.unlogged-page {
  justify-content: flex-start;
}
.navigation-list ion-col {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navigation-list a {
  text-decoration: none;
  cursor: pointer;
  color: #535252;
}

.navigation-list .login-button {
  background: #08338f;
  color: #fff;
  padding: 10px 0;
  width: 117px;

  border-radius: 0;
  text-transform: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.unlogged-section {
  padding: 30px 0;
}
.unlogged-section p {
  line-height: 24px;
}
.unlogged-section-title {
  font-size: 30px;
  color: #00539e;
  margin-bottom: 30px;
  font-weight: 400;
}

.about-us-section {
  box-shadow: inset 0 6px 6px rgba(0, 0, 0, 0.1);
}

.how-works {
  background-color: #f2f1f6;
}

.how-works.unlogged-section .item {
  padding: 0 130px;
}

.to-top-link {
  color: #08338f;
  font-size: 14px;
  font-weight: bold;
  margin: 20px 0;
}

.ready-strip p {
  background-color: #08338f;
  color: #fff !important;
  font-weight: bold;
  text-align: center;
  margin: 40px 0 30px 0;
  padding: 20px 15px;
  font-size: 20px;
}

.how-join .join-button {
  width: 175px;
  color: #08338f;
  text-decoration: none !important;
  --background: #fff;
  font-size: 14px;
  display: inline-block;
  height: 40px;
  font-weight: bold;
  text-transform: none;
  border: 1px solid #08338f;
  --box-shadow: none;
}

.how-join .item-arrow {
  margin: 10px 0;
}

.benefits-section {
  background-color: #efeef4;
}
.benefits-section .cards-img {
  margin-bottom: 15px;
}
.benefits-section .club-cards h4 {
  font-weight: bold;
  font-size: 13px;
  color: var(--mainTextColor);
}
.club-cards {
  text-align: center;
}
.promo {
  margin-top: 20px;
}

.promo img {
  width: 100%;
}

.map-details {
  background-color: #0a559c;
  padding: 20px !important;
  color: #fff;
  font-size: 16px;
}
.map-details h3 {
  text-align: left !important;
  padding: 0 !important;
  color: #fff !important;
  font-size: 36px;
  margin-bottom: 20px;
}
.map-details p {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}
.map-details ion-router-link {
  color: #fff;
  font-weight: normal;
  text-decoration: underline;
}

.map-picture {
  width: 100%;
}

.branch-popover {
  --width: 400px;
}

.knowledge-card {
  margin: 0;
  height: 100%;
  border-bottom: #15acda solid 4px;
}
.knowledge-card-title {
  line-height: 1.1rem;
  max-width: 90%;
  height: 36px;
  font-size: 16px;
  font-weight: bold;
  overflow: hidden;
  color: var(--mainTextColor);
}
.knowledge-card-subtitle {
  font-size: 0.857rem;
  font-weight: 300;
  margin-top: 3px;
}
.knowledge-card .card-media {
  width: 50%;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  position: relative;
}
/* .knowledge-card .card-media:after {
  transition: all .2s ease-in-out;
  content: '';
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  position: absolute;
  background-color: red;
}
.knowledge-card .card-media:hover:after {
  transition: all .2s ease-in-out;
} */
.knowledge-card .card-media img {
  width: 100%;
}

.knowledge-card .card-media iframe {
  width: 100%;
  height: 250px;
}
.knowledge-card ion-card-content {
  width: 50%;
  padding: 7px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.knowledge-card-title {
  font-size: 14px;
}

.knowledge-items-grid ion-spinner {
  display: block;
  margin: 0 auto;
}
.catalog.header-top-button {
  display: flex;
  align-items: center;
  text-decoration: underline;
  color: var(--active-desk-color);
}
.online-catalog-btn {
  margin-right: 18px;
}
.online-catalog-text {
  text-decoration: none;
  color: var(--content-color);
  font-size: 14px;
  opacity: 0.7;
  display: flex;
  align-items: center;
}

.logged-menu-link {
  display: none;
}
.footer-menu-col {
  align-items: center;
  display: flex;
}
.mobile-header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.redeem-modal {
  --height: 231px;
}

.react-tooltip-lite {
  width: 100px;
  padding: 5px;
  font-size: 13px;
  text-align: center;
  background: #333;
  color: white;
  border-radius: 4px;
}
.react-tooltip-lite-arrow {
  border-color: #333;
}

.footer-btn.hidden-button {
  display: none !important;
}
.user-popopver-button {
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  --color: var(--mainTextColor);
  font-weight: bold;
}
.user-popopver-button a {
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--mainTextColor);
  font-weight: bold;
}
.header-user-popover {
  background: #ffffff;
}
.header-user-list {
  background: transparent;
}

.header-user-list .item {
  --background: transparent;
}
.header-user-popover ion-item {
  --border-color: transparent;
}
.desktop-header-user-block {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}
.knowledge-modal {
  padding: 0;
  --box-shadow: none;
  --background: transparent;
}
.knowledge-modal .modal-wrapper {
  --width: 100%;
  --height: 100%;
}
.knowledge-modal .ion-page {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.knowledge-modal a {
  display: flex;
  align-items: center;
  justify-content: center;
}
.knowledge-modal .modal-wrapper .ion-page {
  background: #ffffff;
}

.update-profile-grid .field-wrapper {
  --padding-top: 0;
  --padding-bottom: 0;
  margin-bottom: 5px;
}

.reg-form .register-button {
  margin-top: 20px;
}

.login-form .field-wrapper {
  margin-bottom: 15px;
}

.login-content ion-button.secondary-btn {
  height: 30px;
  min-height: auto;
}
.picker-wrapper {
  --background: #ffffff;
}

.picker-wrapper .picker-opt {
  --box-shadow: none;
  color: var(--mainTextColor);
}

.picker-wrapper .picker-prefix,
.picker-suffix,
.picker-wrapper .picker-opt.picker-opt-selected {
  color: var(--currentColor) !important;
  font-weight: bold;
}
.picker-wrapper .picker-toolbar .picker-button {
  color: var(--currentColor) !important;
  font-weight: bold;
}

.accordion-label {
  font-weight: bold;
}

.accordion__button:before {
  position: relative;
  bottom: 1px;
  transition: all 0.2s ease-in-out;
}
.user-popopver-button {
  --background-hover: #15acda;
  --background-hover-opacity: 1;
  --color-hover: #ffffff;
}
.user-popopver-button:hover a {
  color: #ffffff;
}

.how-to-modal {
  padding: 20px 50px;
}
.how-to-modal .modal-wrapper {
  height: 450px;
  background-color: transparent;
}
.how-to-modal .modal-wrapper .ion-page {
  padding: 0;
  background-color: transparent;
  --box-shadow: none;
  /* overflow: hidden; */
}
.how-to-modal .modal-wrapper .ion-page iframe {
  width: 90%;
  height: 100%;
}
.how-to-modal .close-modal {
  right: -2px;
  top: -4px;
}

.card-hover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(1, 43, 93, 0.9);
  opacity: 0;
  visibility: hidden;
}
.card-media {
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  z-index: 1;
}
.card-media:hover .card-hover {
  opacity: 1;
  transition: all 0.2s ease-in-out;
  visibility: visible;
}
img.card-hover-label {
  width: 100px;
  max-width: 100px;
}
span.card-hover-label {
  color: #ffffff;
  font-size: 24px;
}

.sc-ion-modal-md-h:first-of-type {
  --box-shadow: none !important;
}

.center-row {
  width: 80%;
  margin: 0 auto;
}

.bottom-navigation ul {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  text-decoration: none;
  margin: 40px 0;
  padding: 0;
}
.bottom-navigation a {
  text-decoration: none;
  display: inline-block;
  color: #474444;
  font-size: 18px;
  padding: 0 15px;
  font-weight: normal;
}
.bottom-navigation a:hover {
  text-decoration: underline;
}

.bottom-navigation.bottom-navigation-left li:last-child {
  border-left: 1px solid #474444;
}
.bottom-navigation.bottom-navigation-right li:last-child {
  border-right: 1px solid #474444;
}

.map-section.unlogged-section {
  padding-bottom: 0;
}

.installer-information {
  background-color: #f3f4f4;
  margin-top: 10px;
}
.installer-card-img {
  height: 25px;
  width: 39px;
}

.form-errors-block {
  width: 100%;
  padding: 15px;
  background-color: #db3431;
  transition: 0.2s ease-in-out;
  margin-bottom: 15px;
}
.form-errors-block ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.form-errors-block li {
  color: #fff;
  margin-bottom: 3px;
  font-size: 14px;
}
.installers-card-title-div {
  display: flex;
  align-items: center;
  padding: 10px 10px;
  color: #002d59;
}
.installers-card-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
[dir="ltr"] .circle-icon {
  margin-right: 10px;
}
[dir="rtl"] .circle-icon {
  margin-left: 10px;
}
.installers-card-sub-main {
  font-size: 1rem;
  margin-bottom: 5px;
}
[dir="rtl"] .installers-card-sub-main {
  text-align: right;
}

.installers-card-phone {
  display: flex;
  align-items: center;
}

.installers-card-mail {
  display: flex;
  align-items: center;
}
.installers-icon {
  margin-right: 5px;
}
.installers-member-level {
  color: #034ea5;
}

.installers-address {
  cursor: pointer;
}

/* cards layout */
.card-wrapper-default .card-name,
.card-wrapper-default .card-date {
  display: none !important;
}

.slide-card {
  --box-shadow: none;
}
.slide-card .card-wrapper {
  width: 120px;
  height: 78px;
}

.card-modal .card-wrapper {
  position: absolute;
  transform: rotate(90deg);
  width: 100%;
}

.status-update-cert .card-wrapper {
  max-width: 100%;
  display: block;
  width: 200px;
  margin: 0 auto;
  position: relative;
}
.status-update-cert .card-wrapper .card-name {
  font-size: 9px;
  left: 10%;
}

.status-update-cert .card-date {
  font-size: 5px;
  bottom: 8%;
  opacity: 0.8;
}

.card-name {
  color: #ffffff;
  font-size: 7px;
  position: absolute;
  top: 55%;
  left: 6%;
  opacity: 0.8;
  text-transform: none;
  text-align: left;
  max-width: 65%;
}
.card-date {
  color: #ffffff;
  font-size: 4px;
  position: absolute;
  bottom: 7%;
  left: 19%;
  opacity: 0.6;
}

.card-modal .card-name {
  font-size: 18px;
}

.card-modal .card-date {
  font-size: 10px;
  bottom: 12%;
  left: 21%;
}

.card-modal .card-name {
  left: 9% !important;
}
.slide-card .card-name {
  letter-spacing: 0px;
}

/* gold modal card */
.gold-card-wrapper .card-name {
  color: #000000 !important;
  font-weight: bold;
  opacity: 0.8;
  left: 10%;
}

.gold-card-wrapper .card-date {
  color: #000000 !important;
  font-weight: 600;
}
.slide-card .gold-card-wrapper .card-date {
  letter-spacing: -0.7px;
  font-size: 4px;
  bottom: 10%;
  left: 20%;
}

.card-modal .gold-card-wrapper .card-date {
  left: 20%;
  bottom: 12% !important;
}

.status-update-cert .gold-card-wrapper .card-name {
  left: 10%;
}
.status-update-cert .gold-card-wrapper .card-date {
  left: 21%;
  bottom: 11%;
}

/* Platinum modal card */
.platinum-card-wrapper > span {
  color: rgba(226, 213, 141, 1);
}

.slide-card .platinum-card-wrapper .card-name {
  left: 11%;
}
.slide-card .platinum-card-wrapper .card-date {
  left: 19%;
  bottom: 10%;
  font-size: 3px;
}

.status-update-cert .platinum-card-wrapper .card-date {
  left: 20%;
  bottom: 11%;
}

/* diamond card */
.diamond-card-wrapper > span {
  color: #ffffff !important;
}

.card-modal .diamond-card-wrapper .card-date {
  bottom: 9%;
  left: 19%;
}

.navigation-link {
  color: #5b5e67;
  text-decoration: none;
}

.back-button {
  padding-left: 0 !important;
  text-transform: none;
  font-size: 16px;
  --color: var(--currentColor);
}
.personal-msg {
  color: var(--mainTextColor);
}
ion-header ion-toolbar {
  --background: #ffffff;
}

.points-table ion-col {
  color: var(--mainTextColor);
}
.status-update-box {
  color: var(--mainTextColor);
}

.home_buy .item-wrapper {
  color: var(--mainTextColor);
}
.segment-btn {
  --color: var(--mainTextColor);
}
.unlogged-mobile-menu {
  --background: #ffffff;
}
.level-form select {
  color: var(--mainTextColor);
}

.level-form-wrapper {
  display: inline-block;
  text-align: left;
  height: 60vh;
  width: 70%;
  margin-bottom: 20px;
}
.unlogged-page p {
  color: var(--mainTextColor);
}

.unlogged-page .map-details p {
  color: #ffffff;
}

.gifts-container .spinner {
  display: block;
  margin: 0 auto 20px auto;
}

.segment-toolbar {
  background-color: #fff;
}
.segment-toolbar ion-segment {
  background-color: #fff;
}

.installers-map-wrapper {
  height: 450px;
}

.form-line {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.15);
  margin: 12px 0 17px 0;
}

.spinner-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.spinner-wrapper ion-spinner {
  --color: #2b4b85;
}

.navigation-list_mobile {
  display: none;
}

.mobile-unlogged-menu-list ion-item {
  --inner-padding-end: 0;
}
.popover-wrapper .mobile-country-menu-list ion-item {
  --padding-start: 0;
  --inner-padding-end: 0;
}

.mobile-unlogged-menu-list > ion-item {
  --border-color: transparent;
}

.login_arrow {
  width: 21px;
  position: relative;
  top: 2px;
}

.mobile-country-menu-list ion-button img {
  width: 29px;
  height: 27px;
}

.close-modal-btn {
  position: absolute;
  right: 5px;
  top: 5px;
  --background: var(--currentColor);
}

.support-modal .modal-wrapper {
  height: 200px;
  width: 100%;
  max-width: 400px;
  min-width: 320px;
  height: 200px;
}
.by-clicking-link {
  text-decoration: none;
}

.popover-wrapper .mobile-country-menu-list ion-item:first-child img {
  width: auto;
  height: auto;
}
